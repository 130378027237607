<template>
  <div>
    <my-nav-bar
      title="金属行情"
      left-text="返回"
      left-arrow
    />
    <van-search
      v-model="listQuery.name"
      class="search-bar"
      placeholder="请输入金属名称"
      shape="round"
      clearable
      @search="onSearch"
    />
    <van-cell class="date-seletor" title="日期" :value="listQuery.date" :border="false" is-link center @click="show = true" />
    <div class="main-container">
      <van-empty v-if="showEmpty" description="暂无数据" />
      <div v-for="(metal) in list" :key="metal.id" class="metal-view">
        <van-cell title="金属名称" :value="metal.name" />
        <van-cell title="最低单价" :value="metal.min_price" />
        <van-cell title="最高单价" :value="metal.max_price" />
        <van-cell title="平均单价" :value="metal.avg_price" />
        <van-cell title="单位" :value="metal.unit" />
        <van-cell title="涨幅" :value="metal.diff_price" />
      </div>
    </div>

    <van-popup v-model="show" position="bottom">
      <van-datetime-picker
        v-model="pickerDate"
        type="date"
        title="选择年月日"
        @confirm="handleDateConfirm"
        @cancel="show = false"
      />
    </van-popup>

  </div>
</template>

<script>
import myNavBar from '@/components/my-nav-bar.vue'
import { toDateString } from '@/utils'
import { getMetals } from '@/api/metal'
export default {
  components: { myNavBar },
  data() {
    return {
      listQuery: {
        name: '',
        date: ''
      },
      show: false,
      list: [],
      pickerDate: new Date(),
      showEmpty: false
    }
  },
  created() {
    this.getList()
  },
  methods: {
    getList() {
      this.beginLoad()
      getMetals(this.listQuery).then(res => {
        this.list = res.data
        this.showEmpty = this.list.length === 0
        this.endLoad()
      })
    },
    handleDateConfirm(date) {
      this.listQuery.date = toDateString(date)
      this.show = false
      this.getList()
    },
    onSearch() {
      this.getList()
    }
  }
}
</script>

<style lang="scss" scoped>
  .search-bar {
    margin-top: 0;
  }
  .date-seletor {
    position: fixed;
    top: 92px;
    z-index: 9;
  }
  .main-container {
    margin-top: 145px;
  }
  .metal-view {
    margin-bottom: 10px;
  }
</style>
