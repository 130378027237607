var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('my-nav-bar', {
    attrs: {
      "title": "金属行情",
      "left-text": "返回",
      "left-arrow": ""
    }
  }), _c('van-search', {
    staticClass: "search-bar",
    attrs: {
      "placeholder": "请输入金属名称",
      "shape": "round",
      "clearable": ""
    },
    on: {
      "search": _vm.onSearch
    },
    model: {
      value: _vm.listQuery.name,
      callback: function callback($$v) {
        _vm.$set(_vm.listQuery, "name", $$v);
      },
      expression: "listQuery.name"
    }
  }), _c('van-cell', {
    staticClass: "date-seletor",
    attrs: {
      "title": "日期",
      "value": _vm.listQuery.date,
      "border": false,
      "is-link": "",
      "center": ""
    },
    on: {
      "click": function click($event) {
        _vm.show = true;
      }
    }
  }), _c('div', {
    staticClass: "main-container"
  }, [_vm.showEmpty ? _c('van-empty', {
    attrs: {
      "description": "暂无数据"
    }
  }) : _vm._e(), _vm._l(_vm.list, function (metal) {
    return _c('div', {
      key: metal.id,
      staticClass: "metal-view"
    }, [_c('van-cell', {
      attrs: {
        "title": "金属名称",
        "value": metal.name
      }
    }), _c('van-cell', {
      attrs: {
        "title": "最低单价",
        "value": metal.min_price
      }
    }), _c('van-cell', {
      attrs: {
        "title": "最高单价",
        "value": metal.max_price
      }
    }), _c('van-cell', {
      attrs: {
        "title": "平均单价",
        "value": metal.avg_price
      }
    }), _c('van-cell', {
      attrs: {
        "title": "单位",
        "value": metal.unit
      }
    }), _c('van-cell', {
      attrs: {
        "title": "涨幅",
        "value": metal.diff_price
      }
    })], 1);
  })], 2), _c('van-popup', {
    attrs: {
      "position": "bottom"
    },
    model: {
      value: _vm.show,
      callback: function callback($$v) {
        _vm.show = $$v;
      },
      expression: "show"
    }
  }, [_c('van-datetime-picker', {
    attrs: {
      "type": "date",
      "title": "选择年月日"
    },
    on: {
      "confirm": _vm.handleDateConfirm,
      "cancel": function cancel($event) {
        _vm.show = false;
      }
    },
    model: {
      value: _vm.pickerDate,
      callback: function callback($$v) {
        _vm.pickerDate = $$v;
      },
      expression: "pickerDate"
    }
  })], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }